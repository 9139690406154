import React from "react"
import Flex from "@components/elements/flex"
import Container from "@components/layout/container"
import { graphql, useStaticQuery } from "gatsby"
import { processImages } from "@utils/process-images"
import Image from "@components/image"
import Heading from "@components/elements/heading"
import Span from "@components/elements/span"
import Paragraph from "@components/elements/paragraph"
import Button from "@components/elements/button"
import { useTheme } from "styled-components"
import GradientText from "@components/common/gradient-text"
import Div from "@components/elements/div"

export const SocialProofSection = () => {
  const data = useStaticQuery(
    graphql`
      query SocialProof {
        images: allDirectusFiles(
          filter: {
            directus_id: { in: ["b72ab500-4c96-4579-b942-332d48f5e741"] }
          }
        ) {
          edges {
            node {
              directus_id
              cdn
              id
              placeholder
              title
            }
          }
        }
      }
    `
  )

  const images = processImages(data.images.edges)
  const { color } = useTheme()

  return (
    <Container>
      <Flex
        maxWidth="100%"
        padding="16rem 0 8rem"
        paddingSm="12rem 0 6rem"
        gap="2rem"
        alignItems="center"
      >
        <Flex
          gap="2rem"
          alignItems="center"
          margin="0 0 4rem"
          position="relative"
        >
          <Flex maxWidth="900px" alignItems="flex-start">
            <Heading fontSize="4rem" as="h2" margin="0 0 2rem">
              We love what we do, and
              <Span color="#707EFA"> so do our customers</Span>
            </Heading>
            <Paragraph margin="0 0 3rem">
              PowerMetrics has been helping teams just like yours organize,
              visualize, and share metrics since 2019. With extensive docs, an
              AI-powered help UI, friendly support staff, a free tier, and
              surprisingly low pricing, now&apos;s the time to give us a try.
            </Paragraph>
            <Image
              eager
              radius={1}
              style={{ maxWidth: "70%", margin: "0 0 4rem" }}
              file={images["b72ab500-4c96-4579-b942-332d48f5e741"]}
            />
            <Button.PowerMetrics noticeColor={color.indigo600}>
              Get Started Free
            </Button.PowerMetrics>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  )
}

export const SocialProofSectionGeneric = () => {
  const data = useStaticQuery(
    graphql`
      query SocialProof {
        images: allDirectusFiles(
          filter: {
            directus_id: { in: ["b72ab500-4c96-4579-b942-332d48f5e741"] }
          }
        ) {
          edges {
            node {
              directus_id
              cdn
              id
              placeholder
              title
            }
          }
        }
      }
    `
  )

  const images = processImages(data.images.edges)

  return (
    <Container>
      <Flex maxWidth="100%" gap="2rem" alignItems="center">
        <Flex gap="2rem" alignItems="center" position="relative" center>
          <Flex maxWidth="900px" alignItems="flex-start">
            <Heading
              style={{ fontSize: "clamp(2rem, 3vw + 1rem, 4rem)" }}
              as="h2"
              margin="0 auto 2rem"
              marginSm="0 auto 1rem"
            >
              We love what we do, and&nbsp;
              <GradientText
                gradient={
                  "linear-gradient(90deg, #707EFA 12%, #1E6EE6 52.5%, #18AED8 88%)"
                }
              >
                so do our customers
              </GradientText>
            </Heading>
            <Paragraph margin="0 auto 2rem" marginSm="0 auto 1rem">
              At Klipfolio, we believe everyone can make successful data-driven
              decisions. They just need the right tools. Our mission is to break
              down the barriers in data analytics and make it accessible to all,
              regardless of technical background or role.
            </Paragraph>
            <Paragraph>
              Recognized as <b>most recommended</b> (Software Advice) and
              the&nbsp;
              <b>category leader</b> (G2 Crowd) in Analytics and Business
              Intelligence.
            </Paragraph>
            <Div margin="3rem auto 0" marginSm="2rem auto 0">
              <Image
                eager
                radius={1}
                style={{ maxWidth: "60%", margin: "0 auto" }}
                file={images["b72ab500-4c96-4579-b942-332d48f5e741"]}
              />
            </Div>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  )
}
